.sportLPV3 .MuiImageListItemBar-subtitle {
    line-height: 2;
   }

   .sportLPV3__content-title {
       display: flex;
       justify-content: space-between;
       align-items: center;
       
   }

   .sportLPV3__content-title button {
    background-color: #0082C3;
    color: #fff;
   }

   .sportLPV3__content-title button:hover {
    background-color: #004876;
   }


   @media(min-width: 1200px) {
    .sportLPV3__content {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        max-width: 1800px;
        margin: 50px auto 0 auto;
        /*border: 1px solid red;*/
    }
    .sportLPV3__components {
        width: 42%;
        /*width: 100%;*/
      /* width: 0;*/
       /* border: 1px solid green;*/
       transition: width 500ms ease-out;
    }
    .sportLPV3__code-editor {
        width: 55%;
       /*width: 10%;*/
      transition: width 500ms ease-out;
    }
  /*  .sportLPV3__code-editor .code-editor {
       opacity: 0;
    }
    .sportLPV3__code-editor button {
        display: none;
    }*/
   }

  