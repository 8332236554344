.settings {
  padding: 16px;
}

.settings__section {
  border: 1px solid #eee;

  margin-bottom: 30px;
  padding: 10px;
}

.settings__section:last-child {
  border: none;
}

.settings__subtitle {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 24px;
}

.settings__inputWrapper {
  margin-bottom: 20px;
}

.settings__inputWrapper:last-child {
  margin-top: 30px;
}

.settings__inputWrapper select {
  width: 280px;
}

.settings__inputWrapper input {
  width: 310px;
}
