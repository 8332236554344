.footer {
  background-color: #3643BA;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  padding: 2px 0;
  z-index: 6;
  opacity: .5; 
  display: none;
}
