.updates {
    margin: 30px 0;
}
.updates__table {
    max-height: 300px;
    overflow-y: auto;
    width: 95%;
    margin: 0 auto;

}


@media(min-width: 1800px) {

    .updates__table {
        max-height: 500px;
        margin-top: 80px;
    
    }

}