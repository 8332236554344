.home {
  /*border: 1px solid red;*/
  padding: 0 12px;
}

.home__img-container {
  text-align: center;
  margin-top: 54px;
}

.home__img-container img {
  width: 44%;
  height: auto;
}
 
.home__container {
  /*text-align: center;*/
  margin-top: 20px
}

.welcome-title {
  font-size: 20px !important;
  text-transform: none !important;
  text-align: left;
  margin-top: 40px;
  padding: 30px;
}
.welcome-subtitle {
  font-size: 16px !important;
}

.update-algolia-sliders-message {
  padding: 30px;
  text-align: left;
  text-transform: none !important;
  font-size: 16px !important;

}

.home__left {
  /*margin-bottom: 60px*/
}

.home__right {
  margin-top: 12px;
}

/* home page settings missing */
.home-settings-missing-message {
  text-align: center;
}

.home-settings-missing-message__btn-container button {
  min-width: 250px;
  margin-top: 20px;
}

.home__icon-desktop {
  display: none;
}

@media(min-width: 1200px) {

  .home__container {
    /*display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;*/
    margin-top: 20px;
  }
  .home__left,
  .home__right {
    /*width: 50%;*/
    padding: 32px;
    /*border: 1px solid rebeccapurple;*/
  }
  .home__right {
    text-align: left;
  }
  .home__left {

  }
  .home__container .welcome-title {
    text-align: left;
    font-size: 54px !important;
  }
  .home__container .welcome-subtitle {
    text-align: left;
    font-size: 24px !important;
  }
  .home__container .title {
    text-transform: none !important;
  }

  .home__icon-desktop {
    display: block;
  }
  .home__icon-mobile {
    display: none;
  }
 
}