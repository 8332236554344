
.sportLPV2 {
    padding: 0 10px;
}

.sportLPV2__boxes {
    margin-bottom: 36px;
    margin-top: 10px;
}

.sportLPV2__box {
    text-align: center;
    border: 1px solid lightgray;
    padding: 30px 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.sportLPV2__box-title {
    text-transform: uppercase;
    color: #0082C3;
}


.sportLPV2__box-btn-container a {
    text-decoration: none;
}

.sportLPV2__box-btn-container button {
    min-width: 200px;
    background-color: #0082C3;
}
.sportLPV2__box-btn-container button:hover {
    background-color: #004876;
}

.sportLPV2__example-link {
    cursor: pointer;
    color: #0082C3;
    text-decoration: underline;
    text-transform: uppercase;
}


@media(min-width: 760px) {
    .sportLPV2__boxes {
        display: flex;
        justify-content: space-evenly;
        margin-top: 16px;
    }
    .sportLPV2__box {
        width: 48%;
    }
}

@media(min-width: 1200px) {
    .sportLPV2__boxes {
        margin-top: 22px;
    }
    .sportLPV2__box {
        padding: 41px 23px;   
        min-height: 420px;
    }
    .sportLPV2__example-link {
        padding-left: 20px;
    }
}


@media(min-width: 1800px) {
    .sportLPV2__boxes {
        margin-top: 40px;
    }
    .sportLPV2__box {   
        min-height: 700px;
    }
    .sportLPV2__box-title {
        font-size: 40px;
    }
    .sportLPV2__example-link {
        padding-left: 32px;
    }
}