*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

/* GLOBAL STYLES */
.title {
  text-align: center;
  text-transform: uppercase; 
  margin-bottom: 10px;
 /* color: #004876; */
 color: #101010;
}


.primary-btn {
  background: #fff !important;
  border: 1px solid #3643BA !important;
  color: #3643BA !important;
}
.secondary-btn {
  background: #3643BA !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.card {
  /* border: 1px solid yellow;*/
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  /* margin-right: 35px; */
}

@media (min-width: 1200px) {
  .cards {
    display: flex;
    justify-content: space-evenly;
    /*flex-wrap: wrap;*/
    padding: 20px;
  }
}


.primary-button-container button{
  background-color: #3643BA;
  color: #fff;
}
.primary-button-container button:hover{
  background-color: #323894;
}
 
.page {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
 }

/* start transition group styles */

.remove-btn {
  margin-right: 0.5rem;
}

.item-enter {
  opacity: 0;
  z-index: 1;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* end transition group styles */


/* start scrollbar style */
/* For WebKit (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: lightgray; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: gray; /* Color of the thumb on hover */
}

/* For Firefox */
::-moz-scrollbar {
  width: 8px;
}

::-moz-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 4px;
}

::-moz-scrollbar-thumb:hover {
  background-color: gray;
}

/* end scrollbar style */

/* start feature box styling */
.MuiImageListItem-item .MuiImageListItemBar-root {
  height: 100% !important;
  display: block !important;
  background: rgba(0, 0, 0, 0.6) !important;
 }
 .MuiImageListItem-item .MuiImageListItemBar-subtitle {
  overflow: visible !important;
  white-space: normal !important;
  line-height: 1 !important;
}
/* end feature box styling */