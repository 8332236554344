.hpComponentsPage__title {
  text-align: center;
  margin-bottom: 10px;
}

.hpComponentsPage__card {
  /* border: 1px solid yellow;*/
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
}

@media (min-width: 1200px) {
  .hpComponentsPage__cardsContainer {
    display: flex;
    justify-content: space-around;

    flex-wrap: wrap;
  }
}
