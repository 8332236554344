.popupImg {
    width: 50%;
    height: auto;
  }
  
  .popupInputsContainer {
    margin: 14px 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    text-align: left;
  }
  
  .popupInputsContainer__titleOfInputsGroup {
    margin: 10px 0;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #3643BA;
  }
  
  .popupInputsContainer__wrapper {
    margin: 20px 0;
  }
  
  .popupInputsContainer__sectionWrapper {
    border-bottom: 1px solid lightgray;
    margin: 10px 0;
  }
  