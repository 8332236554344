.header {
  margin-bottom: 76px;
}

.header .MuiToolbar-regular {
  min-height: 56px;
 /* background: #004876; */
 background: #fff;
}

.header__icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__profile-icon {
  margin-right: 10px;
  display: block; 
  
}

.header__profile-icon:hover {
  transform: scale(1.06);
}

.header__logout-btn {
  color: #3643BA;
  background-color: #fff; 
  outline: none;
  border: 1px solid transparent;
  padding: 6px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 10px;
  transition: all .2s ease-out;
}

.header__logout-btn:hover {
  color: #fff;
  background-color: #3643BA;
  border: 1px solid #fff;
}



.settings-missing-animate {
  animation-name: settings-missing-animate-icon;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.header__country-flag {
  display: none;
  margin-right:20px;
}

@media(min-width: 760px) {
  .header__country-flag {
    display: block;
  }
}

@keyframes settings-missing-animate-icon {
  0% {
    transform: scale(1.1);
    color: #E20C18;
  }
  50% {
    transform: scale(0.9);
    color: #fff;
  }
  100% {
    transform: scale(1.1);
    color: #E20C18;
  }
}