@keyframes generateBtnAnimation {
    0% {
        transform: scale(1.1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.1);
    }

}

.generate-btn-animation-active {
    animation-duration: 4s;
    animation-name: generateBtnAnimation;
    
}